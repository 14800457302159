/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import {
  fetchCategories,
  fetchMyInventoryCategories
} from "../../../../../actions/categories/categories"
import "antd/dist/antd.css"
import { useHtmlClassService } from "../../../_core/MetronicLayout"
import { AsideMenuList } from "./AsideMenuList"
import GlobalLoader from "../../../../../common/loaders/LoadingBox"
import AsideToggelMenu from "./AsideToggelMenu"
import { useLocation } from "react-router-dom"
import { useShopNameContext } from "../../../../../app/ShopContext"

export function AsideMenu() {
  const { toggel } = useSelector(
    (state) => ({
      toggel: state.toggel && state.toggel.toggelState
    }),
    shallowEqual
  )

  const { refreshFlag } = useSelector(
    (state) => ({
      refreshFlag: state.common && state.common?.refresh_flag
    }),
    shallowEqual
  )

  const location = useLocation()
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    }
  }, [uiService])

  const ShopNameContext = useShopNameContext()

  const dispatch = useDispatch()
  const [treeData, setTreeData] = useState([])
  const [loader, setLoader] = useState(true)

  const query = {
    shop: ShopNameContext.shopName
  }

  useEffect(() => {
    if (location.pathname && location.pathname === "/my-inventory") {
      setLoader(true)

      dispatch(fetchMyInventoryCategories(query)).then((res) => {
        myInventoryTreeData(res.payload)
      })

      setLoader(false)
    } else {
      setLoader(true)

      dispatch(fetchCategories(query)).then((res) => {
        createTreeData(res.payload)
      })

      setLoader(false)
    }
  }, [dispatch, location])

  useEffect(() => {
    if (refreshFlag !== null && refreshFlag === true) {
      dispatch(fetchMyInventoryCategories(query)).then((res) => {
        myInventoryTreeData(res.payload)
      })
    }
  }, [dispatch, refreshFlag])

  useEffect(() => {
    const elements = document.querySelectorAll(".menu-item")

    elements.forEach((element) => {
      if (element.classList.contains("menu-item-open")) {
        element.classList.remove("menu-item-open")
      }
    })
  }, [location.pathname])

  const createTreeData = (categories) => {
    categories
      .filter((c) => c.category_parent && c.hide === false)
      .forEach((c) => {
        // Assign them to that parent.
        const parent = categories.find(
          (p) => p.cart_category_id === c.category_parent && p.hide === false
        )
        parent.children = parent.children || []
        parent.children.push(c)
      })

    categories = categories.filter(
      (c) => c.category_parent === 0 && c.hide === false
    )

    // Recursive function to process nested items
    const extractCategoryInfo = (item) => {
      const result = {
        title: `${
          item.category_name
        } ${item.cart_product_count !== undefined &&
          `(${item.cart_product_count})`}`,
        key: item.cart_category_id
      }

      if (item.children) {
        result.children = item.children.map(extractCategoryInfo)
      }

      return result
    }

    // Process the data recursively
    const transformedData = categories.map(extractCategoryInfo)

    // const parentCats = categories?.filter((item) => {
    //   if (item.category_parent === 0 && item.hide === false) return true
    // })

    // const treeData = []
    // parentCats &&
    //   parentCats.forEach((item) => {
    //     const children = []

    //     categories &&
    //       categories.forEach((category) => {
    //         if (
    //           category.category_parent === item.cart_category_id &&
    //           category.hide === false
    //         ) {
    //           children.push({
    //             title: `${
    //               category.category_name
    //             } ${category.cart_product_count !== undefined &&
    //               `(${category.cart_product_count})`}`,
    //             key: category.cart_category_id
    //           })
    //         }
    //       })

    //     if (children.length === 0) {
    //       treeData.push({
    //         title: `${item.category_name} ${item.cart_product_count !==
    //           undefined && `(${item.cart_product_count})`}`,
    //         key: item.cart_category_id
    //       })
    //     } else {
    //       treeData.push({
    //         title: `${item.category_name} ${item.cart_product_count !==
    //           undefined && `(${item.cart_product_count})`}`,
    //         key: item.cart_category_id,
    //         children: children
    //       })
    //     }
    //   })

    setTreeData(transformedData)
  }

  const myInventoryTreeData = (categories) => {
    
    categories
      .filter((c) => c.category_parent && c.hide === false)
      .forEach((c) => {
        // Assign them to that parent.
        const parent = categories.find(
          (p) => p.cart_category_id === c.category_parent && p.hide === false
        )
        parent.children = parent.children || []
        parent.children.push(c)
      })

    categories = categories.filter(
      (c) => c.category_parent === 0 && c.hide === false
    )

    // Recursive function to process nested items
    const extractCategoryInfo = (item) => {
      const result = {
        title: `${item.category_name}`,
        key: item.cart_category_id
      }

      if (item.children) {
        result.children = item.children.map(extractCategoryInfo)
      }

      return result
    }

    // Process the data recursively
    const transformedData = categories.map(extractCategoryInfo)

    // const parentCats = categories?.filter((item) => {
    //   if (item.category_parent === 0 && item.hide === false) return true
    // })
    // const treeData = []
    // parentCats &&
    //   parentCats.forEach((item) => {
    //     const children = []

    //     categories &&
    //       categories.forEach((category) => {
    //         if (
    //           category.category_parent === item.cart_category_id &&
    //           category.hide === false
    //         ) {
    //           children.push({
    //             // title: `${category.category_name} ${category.product_count ? `(${category.product_count})` : "(0)"}`,
    //             title: `${category.category_name}`,
    //             key: category.cart_category_id
    //           })
    //         }
    //       })

    //     if (children.length === 0) {
    //       treeData.push({
    //         // title: `${item.category_name} ${item.product_count ? `(${item.product_count})` : "(0)"}`,
    //         title: `${item.category_name}`,
    //         key: item.cart_category_id
    //       })
    //     } else {
    //       treeData.push({
    //         // title: `${item.category_name} ${item.product_count ? `(${item.product_count})` : "(0)"}`,
    //         title: `${item.category_name}`,
    //         key: item.cart_category_id,
    //         children: children
    //       })
    //     }
    //   })

    setTreeData(transformedData)
  }

  layoutProps.categoryTree = treeData

  return (
    <>
      {loader && <GlobalLoader />}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {toggel ? (
          <AsideMenuList layoutProps={layoutProps} />
        ) : (
          <AsideToggelMenu layoutProps={layoutProps} />
        )}
      </div>
    </>
  )
}
